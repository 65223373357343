<template>
  <div class="th-discount-menu">
    <div class="mb-4">
      <h4 class="">Harga Diskon</h4>
    </div>
    <div class="mb-4 d-flex justify-content-between align-items-center">
      <div class="">Harga saat ini: {{rupiahPrice(courseData.price)}}</div>
      <div class="">
        <a-button :disabled="isEdit" @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Tambahkan Harga Diskon
        </a-button>
        <a-modal v-model:visible="modalAddVisible" title="Tambahkan Harga Diskon" @ok="showModalAdd">
          <a-form layout="vertical" :model="newData">
            <a-row :gutter="[16,0]">
                <a-col :span="18">
                  <a-form-item required label="Harga" name="price" v-bind="validateInfos.price">
                    <div class="d-flex align-items-center">
                      <a-input-number
                        class="w-100 ml-2 mr-1"
                        :min="0"
                        :max="courseData.price"
                        :value="newData.price"
                        :formatter="value => rupiahPrice(value)"
                        :parser="value => value.replace(/Rp\s|\.|\,00/g, '')"
                        @change="e => changeDiscountNumber(e, 'price')"
                      />
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item required label="Besar Diskon" name="percentage" v-bind="validateInfos.percentage">
                    <div class="d-flex align-items-center">
                      <a-input-number
                        class="w-100 mr-1"
                        :value="newData.percentage"
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        @change="e => changeDiscountNumber(e, 'percentage')"
                      />
                    </div>
                  </a-form-item>
                </a-col>
              </a-row>
            <a-form-item required label="Waktu" name="time" v-bind="validateInfos.time">
              <a-range-picker
                class="w-100"
                v-model:value="newData.time"
                :disabled-date="disabledRangeDateAddDiscounted"
                :show-time="{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                }"
                format="DD-MM-YYYY HH:mm:ss" />
            </a-form-item>
          </a-form>
          <template #footer>
            <a-button @click.prevent="confirmAdd" key="submit" type="primary" html-type="submit">Tambahkan</a-button>
          </template>
        </a-modal>
      </div>
    </div>
    <div>
      <a-form layout="inline" >
        <a-table class="w-100" :columns="columns" :data-source="discountDatas" bordered>
          <template #price="{ text, record }">
            <a-form-item name="price" v-bind="validateInfos.price" v-if="record.key === idEdit">
              <a-input-number
              class="table-input"
                :min="0"
                :max="courseData.price"
                :value="newData.price"
                :formatter="value => rupiahPrice(value)"
                :parser="value => value.replace(/Rp\s|\.|\,00/g, '')"
                @change="e => changeDiscountNumber(e, 'price')"
              />
            </a-form-item>
            <span v-else>
              {{rupiahPrice(text)}}
            </span>
          </template>
          <template #percentage="{ text, record }">
            <a-form-item name="percentage" v-if="record.key === idEdit" v-bind="validateInfos.percentage">
              <a-input-number
              class="table-input"
                :value="newData.percentage"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
                @change="e => changeDiscountNumber(e, 'percentage')"
              />
            </a-form-item>
            <span v-else>
              {{Number(text).toFixed(2)}}%
            </span>
          </template>
          <template #startAt="{ record }">
            <a-form-item name="startAt" v-bind="validateInfos.time" v-if="record.key === idEdit">
              <a-date-picker class="table-input" format="DD-MM-YYYY HH:mm:ss" show-time v-model:value="newData.startAt" />
            </a-form-item>
            <span v-else>
              {{record.startAt ? moment(record.startAt).locale('id').format('DD MMMM YYYY HH:mm') : '-'}}
            </span>
          </template>
          <template #endAt="{ record }">
            <a-form-item name="endAt" v-bind="validateInfos.time" v-if="record.key === idEdit">
              <a-date-picker class="table-input" format="DD-MM-YYYY HH:mm:ss" show-time v-model:value="newData.endAt" />
            </a-form-item>
            <span v-else>
              {{record.endAt ? moment(record.endAt).locale('id').format('DD MMMM YYYY HH:mm') : '-'}}
            </span>
          </template>
          <template #status="{ record }">
            <a-tag v-if="moment().isBetween(moment(record.startAt), moment(record.endAt))" color="green">active</a-tag>
            <a-tag v-else color="red">inactive</a-tag>
          </template>
          <template #action="{ record }">
            <span v-if="record.key === idEdit">
              <a @click.prevent="saveEdit(record)">
                <check-outlined></check-outlined>
              </a>
              <a-divider type="vertical" />
              <a @click.prevent="cancelEdit">
                <close-outlined></close-outlined>
              </a>
            </span>
            <span v-else>
              <a @click.prevent="clickEdit(record)">Ubah</a>
              <a-divider type="vertical" />
              <a @click.prevent="deleteDiscountedCourse(record)">Hapus</a>
            </span>
          </template>
        </a-table>
      </a-form>
    </div>
  </div>
</template>

<script>
import { createVNode, watch } from 'vue'
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { reactive, ref, toRef, toRefs } from '@vue/reactivity';
import apiClient from '@/services/axios'
import rupiahPrice from '@/helpers/rupiahPrice'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Modal, notification, message } from 'ant-design-vue';
import { useForm } from '@ant-design-vue/use';
import moment from 'moment'

const columns = [
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    slots: { customRender: 'price' },
  },
  {
    title: 'Besar Diskon',
    dataIndex: 'percentage',
    key: 'percentage',
    slots: { customRender: 'percentage' },
    width: 80,
  },
  {
    title: 'Rentang Tanggal',
    key: 'rentangTanggal',
    children: [
      {
        title: 'Mulai',
        dataIndex: 'startAt',
        key: 'startAt',
        slots: { customRender: 'startAt' },
        align: 'center',
      },
      {
        title: 'Akhir',
        dataIndex: 'endAt',
        key: 'endAt',
        slots: { customRender: 'endAt' },
        align: 'center',
      },
    ],
  },
  {
    title: 'Status',
    key: 'status',
    slots: { customRender: 'status' },
    align: 'center',
    width: 80,
  },
  {
    title: 'Aksi',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: { CheckOutlined, CloseOutlined, PlusOutlined },
  props: {
    course: {
      type: Object,
      default: () => { return {} },
    },
    discountedPriceList: {
      type: Array,
      default: () => { return [] },
    },
  },
  emits: ['fetchCourse', 'fetchDiscountedCourse'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const newData = ref({
      price: 0,
      percentage: 100,
      time: null,
    })
    const { discountedPriceList:discountDatas } = toRefs(props)
    const { course:courseData } = toRefs(props)

    const rulesRef = reactive({
      price: [
        {
          required: true,
          message: 'Harga harus diisi!',
          trigger: 'change',
        },
      ],
      percentage: [
        {
          required: true,
          message: 'Jumlah harus diisi!',
          trigger: 'change',
        },
      ],
      time: [
        {
          required: true,
          message:  `Waktu harus diisi!`,
          trigger: 'change',
        },
      ],
    })

    const { resetFields, validate, validateInfos } = useForm(newData, rulesRef);

    //Edit discountedPrice
    const isEdit = ref(false)
    const idEdit = ref(null)


    const clickEdit = (record) => {
      const { price, percentage, startAt, endAt } = record
      newData.value = {
        price,
        percentage,
        time: [startAt, endAt],
        startAt: startAt ? moment(startAt) : null,
        endAt: endAt ? moment(endAt) : null,
      }
      isEdit.value = true
      idEdit.value = record._id
    }
    const saveEdit = async (record) => {
      await validate()
      Modal.confirm({
        title: `Apa anda yakin ingin mengubah data pada diskon course?`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          try {
            const payload = {
              courseId: route.params.id,
              price: newData.value.price,
              percentage: Number(newData.value.percentage),
              amount: Number(courseData.value.price) - Number(newData.value.price),
              startAt: newData.value.startAt,
              endAt: newData.value.endAt,
            }
            const data = await store.dispatch('discountedCourse/EDIT_DISCOUNTED_COURSE', { payload, idDiscountedCourse: record._id})
            isEdit.value = false
            idEdit.value = null
            newData.value = {
              price: 0,
              percentage: 100,
              time: null,
            }
            emit('fetchDiscountedCourse')

            notification.success({
              message: `Berhasil Mengubah Data Diskon.`,
            })
          } catch (err) {
            notification.error({
              message: `Gagal Mengubah Data Diskon.`,
            })
            console.log(err)
          }
        },
        onCancel() {},
      })
    }
    const cancelEdit = () => {
      isEdit.value = true
      idEdit.value = null
      newData.value = {
        price: 0,
        percentage: 100,
        time: null,
      }
    }
    // Edit

    // Add Discounted Course
    const modalAddVisible = ref(false)
    const showModalAdd = () => {
      modalAddVisible.value = true
    }
    const hideModalAdd = () => {
      modalAddVisible.value = true
      newData.value = {
        price: 0,
        percentage: 100,
        time: null,
      }

    }
    const confirmAdd = async () => {
      try {
        await validate()
        const payload = {
          courseId: route.params.id,
          price: newData.value.price,
          percentage: Number(newData.value.percentage),
          amount: Number(courseData.value.price) - Number(newData.value.price),
          startAt: newData.value.time[0],
          endAt: newData.value.time[1],
        }
        // console.log(console.log(toRaw(newDataRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan data diskon?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('discountedCourse/ADD_DISCOUNTED_COURSE', payload)
              modalAddVisible.value = false
               emit('fetchDiscountedCourse')
               newData.value = {
                price: 0,
                percentage: 100,
                time: null,
              }
              notification.success({
                message: `Berhasil Menambahkan Data Diskon.`,
              })
            } catch (err) {
              notification.error({
              message: `Gagal Menambahkan Data Diskon.`,
            })
            console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) { console.log(err) }
    }
    //

    //delete
    const deleteDiscountedCourse = (record) => {
      Modal.confirm({
        title: `Apa anda yakin ingin menghapus data diskon dari daftar?`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          try {
            const res = await store.dispatch('discountedCourse/DELETE_DISCOUNTED_COURSE', { idDiscountedCourse: record._id })
            emit('fetchDiscountedCourse')
            notification.success({
              message: `Berhasil Menghapus Data Diskon Course.`,
            })
          } catch (err) {
            notification.error({
              message: `Gagal Menghapus Data Diskon Course.`,
            })
            console.log(err)
          }
        },
        onCancel() {},
      })
    }
    //

    watch(newData, (newVal) => {
      console.log(newVal)
    })

    const changeDiscountNumber = (e, key) => {
      let payload = Number(e)
      if (isNaN(payload)) payload = 0
      newData.value[key] = payload
      if (key === 'price') {
        const percentageAmount = (payload / Number(courseData.value.price)) * 100
        // console.log(percentage, key)
        newData.value['percentage'] = 100 - Number(percentageAmount.toFixed(2))
      } else if (key === 'percentage') {
        const discountNow = Number(courseData.value.price) * ( payload / 100 )
        // console.log(discountNow, key)
        newData.value['price'] = Number(courseData.value.price) - discountNow
      }
    }

    const changeDate = (e) => {
      console.log(e)
    }

    const disabledRangeDateAddDiscounted = (start, end) => {
      if (start && end) {
        return start < end
      }
    }


    return {
      isEdit,
      clickEdit,
      saveEdit,
      cancelEdit,
      newData,
      confirmAdd,
      modalAddVisible,
      showModalAdd,
      hideModalAdd,
      deleteDiscountedCourse,
      rulesRef,
      discountDatas,
      columns,
      validateInfos,
      changeDiscountNumber,
      rupiahPrice,
      courseData,
      idEdit,
      changeDate,
      moment,
      disabledRangeDateAddDiscounted,
    }
  },
}
</script>

<style lang="scss">
.th-discount-menu {
}
</style>
